import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './themes/theme';
import { TopPage, ForParents, Contact, 
         ExamplePage, AboutUs, Business,
         TrialApplication,
         PrivacyPolicy
       } from './pages';
import { HelmetProvider } from 'react-helmet-async';
import { urls } from './config';

const App = () => {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path={urls.top} element={<TopPage />} />
            <Route path={urls.business} element={<Business />} />
            <Route path={urls.forParents} element={<ForParents />} />
            <Route path={urls.contact} element={<Contact />} />
            <Route path={urls.aboutUs} element={<AboutUs />} />
            <Route path={urls.trial} element={<TrialApplication />} />
            <Route path={urls.privacyPolicy} element={<PrivacyPolicy />}/>
            <Route path="/example" element={<ExamplePage />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
