/** @jsxImportSource @emotion/react */
import React from 'react';
import { MessageSection } from '../../../../components/common';

const CorporatePhilosophySection = () => {
    return (
        <MessageSection
            title="企業理念" 
            message="私たちは、ダンスと運動を通じて心と体の健康を育みます。地域コミュニティーを目指し、誰もが安心して参加できる環境を整え、健康と笑顔を届けます。" 
            id = "philosophy"
        />
    );
};

export default CorporatePhilosophySection;
