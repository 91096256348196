/** @jsxImportSource @emotion/react */
import React from 'react';
import { SEO, Menu, Footer, TitleSection } from '../../components/common';
import { TrialApplicationFormSection } from './components';

const TrialApplication = () => {
    return (
        <>
        <SEO 
            title="見学会・体験会申込" 
            description="RUKHの見学会・体験会への申し込みページです。見学会や体験会を通じて、RUKHのダンス教室の魅力を体感してください。" 
            keywords="RUKH, 見学会, 体験会, ダンス教室, キッズダンス, 名古屋, 習い事, 見学申込, 体験申込, 子供ダンス"
        />
        <header>
            <Menu />
        </header>
        <main>
            <section>
                <TitleSection
                    englishTitle="Trial Application"
                    japaneseTitle="見学会・体験会申込"
                    remarks="※RUKH KID`Sが入ってる施設に限ります"
                />
            </section>
            <section>
                <TrialApplicationFormSection />
            </section>
        </main>
        <footer>
            <Footer />
        </footer>
        </>
    );
};

export default TrialApplication;
