/** @jsxImportSource @emotion/react */
import React from 'react';
import { SEO, Menu, ContactSection, EventsSection, Footer, InstagramGridSection } from '../../components/common';
import { HeaderSection, AboutUsSection, ContentsSection, TestimonialsSection } from '../100TopPage/components';

const TopPage = () => {
  return (
    <>
      <SEO 
        title="RUKH | ダンス教室" 
        description="RUKHは名古屋市守山区でキッズダンスを提供するダンス教室です。初心者から経験者まで、すべての年齢層に合わせた楽しいレッスンを開催しています。" 
        keywords="ダンス教室, キッズダンス, 名古屋, 名古屋市守山区, 子供向けダンス, ダンスレッスン, RUKH, 習い事, 子供の習い事"
      />
      <header>
        <Menu />
      </header>
      <main>
        <section>
          <HeaderSection />
        </section>
        <section>
          <AboutUsSection />
        </section>
        <section>
          <InstagramGridSection />
        </section>
        <section>
          <ContentsSection />
        </section>
        <section>
          <TestimonialsSection />
        </section>
        <section>
          <EventsSection />
        </section>
        <section>
          <ContactSection />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default TopPage;
