/** @jsxImportSource @emotion/react */
import React from 'react';
import { SEO, Menu, Footer, TitleSection, EventsSection, ContactSection } from '../../components/common';
import { AchievementsSection, BusinessOverviewSection, BusinessSection1, BusinessSection2 } from './components';
import { achievmentData } from '../../config';

const Business = () => {
    return (
        <>
        <SEO 
            title="RUKH | 幼稚園やフィットネス、シニア向けの訪問指導" 
            description="RUKHでは、幼稚園や保育園での訪問指導、フィットネスや陸上教室での指導サポート、そして高齢者向けの運動指導を提供しています。活動実績や事業内容についても掲載しています。" 
            keywords="RUKH, 幼稚園, 保育園, フィットネス, 陸上教室, 高齢者, ボランティア, 健康促進プログラム, 運動指導, 訪問指導"
        />
        <header>
            <Menu />
        </header>
        <main>
            <section>
                <TitleSection englishTitle="Business" japaneseTitle="事業内容" />
            </section>
            <section>
                <BusinessOverviewSection
                    id="rukh-kids"
                    title="RUKH KID`S"
                    main="楽しみながら未来を築く子どもたちの成長ステージ"  
                    sub="子どもたちが楽しみながら自己表現力と協調性を育み、自信を持って未来に向かって歩んでいける環境を提供します。"
                    body="RUKH KID'Sでは、子どもたちが楽しみながら自分のペースで成長できるよう、温かくサポートします。ダンスや運動を通じて、自己表現力、協調性、そして心身の健康を育むことを目指しています。私たちの経験豊富なインストラクターが子どもたち一人ひとりに寄り添い、未来に向けて自信を持って進めるよう、愛情を持って指導しています。"
                    src="/images/800Business/802OverviewSectionImage.png" 
                    alt="overviewSectionImage" 
                />
            </section>
            <section>
                <BusinessSection1 />
                <AchievementsSection achievmentData={achievmentData.business1} />
            </section>
            <section>
                <BusinessOverviewSection
                    id="rukh-fit"
                    title="RUKH FIT"
                    main="すべての世代に活力と健康を届ける場"
                    sub="心と体を無理なく整え、健康的でアクティブな生活を楽しむための運動プログラムを提供します。"
                    body="RUKH FITは、すべての世代の人々が健康で充実した生活を送れるよう、体に負担をかけずに続けられる運動を提供しています。私たちは、心と体のバランスを整え、毎日の生活をより豊かにすることを大切にしています。どんな年齢でも、自分の体と向き合い、前向きに生きる力を引き出すために、健康の土台を築くサポートをしています。"
                    src="/images/800Business/802OverviewSectionImage2.png"
                    alt="overviewSectionImage"
                    reverse={true}
                />
            </section>
            <section>
                <BusinessSection2 />
                <AchievementsSection achievmentData={achievmentData.business2} />
            </section>
            <section>
                <EventsSection />
            </section>
            <secttion>
                <ContactSection />
            </secttion>
        </main>
        <footer>
            <Footer />
        </footer>
        </>
    );
};

export default Business;
