
/** @jsxImportSource @emotion/react */
import React from 'react';
import { SEO, Menu, Footer, TitleSection } from '../../components/common';
import { ContactFormSection } from './components';

const ForParents = () => {
    return (
        <>
        <SEO 
            title="お問い合わせ | RUKH - キッズダンス教室へのご質問はこちら" 
            description="RUKHのキッズダンス教室についてのご質問やご相談はこちらのお問い合わせページからお送りください。名古屋市守山区で開催するレッスンの詳細についてもお問い合わせいただけます。" 
            keywords="RUKH, お問い合わせ, キッズダンス, ダンス教室, 名古屋, 守山区, ご相談, ご質問, ダンスレッスン"
        />
        <header>
            <Menu />
        </header>
        <main>
            <section>
                <TitleSection englishTitle="Form" japaneseTitle="お問い合わせ" />
            </section>
            <section>
                <ContactFormSection />
            </section>
        </main>
        <footer>
            <Footer />
        </footer>
        </>
    );
};

export default ForParents;
