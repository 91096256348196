export { default as urls } from './urls.js';
export { default as navItems } from './navItems.js';
export { default as contents } from './contents.js';
export { default as testimonials } from './testimonials.js';
export { default as introductionImages } from './introductionImages.js';
export { default as benefitsData } from './benefitsData.js';
export { default as featuresData } from './featuresData.js';
export { default as faqData } from './faqData.js';
export { default as challengesData } from './challengesData.js';
export { default as solutions } from './solutions.js';
export { default as achievmentData } from './achievmentData.js';
