/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import { BodyText, H2Text } from '../../../../components/ui';
import SolutionSectionStyles from '../804SolutionSection/SolutionSectionStyles';

const BusinessSection1 = () => {
    const theme = useTheme();
    const classes = SolutionSectionStyles(theme);

    return (
        <Box css={classes.container}>
            <Box css={classes.wrapper}>
                <Box css={classes.content}>
                    <Box css={classes.textContainer}>
                        <H2Text size={theme.typography.title} css={classes.subTitle}>幼稚園や保育園等への訪問指導</H2Text>
                        <BodyText size={theme.typography.body} css={classes.description}>・幼稚園や保育園におけるダンス・運動プログラムの実施</BodyText>
                        <BodyText size={theme.typography.body} css={classes.description}>・幼児期の心身の発達を促すための体験型指導</BodyText>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default BusinessSection1;
