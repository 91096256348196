/** @jsxImportSource @emotion/react */
import React from 'react';
import { SEO, Menu, TitleSection, ContactSection, EventsSection, Footer } from '../../components/common';
import {
  CorporatePhilosophySection, GreetingSection, MissionSection,
  ValueSection, VisionSection
} from './components';

const AboutUs = () => {
  return (
    <>
      <SEO 
        title="About Us | 企業理念、MVV、代表挨拶、経歴" 
        description="合同会社RUKHの企業理念、MVV（ミッション、ビジョン、バリュー）、代表挨拶、経歴について説明するページです。" 
        keywords="合同会社RUKH, 企業理念, MVV, 代表挨拶, 経歴, ミッション, ビジョン, バリュー"
      />
      <header>
        <Menu />
      </header>
      <main>
        <section>
            <TitleSection englishTitle="About Us" japaneseTitle="RUKHについて" />
        </section>
        <section>
            <CorporatePhilosophySection />
        </section>
        <section>
            <MissionSection />
        </section>
        <section>
            <VisionSection />
        </section>
        <section>
            <ValueSection />
        </section>
        <section>
            <GreetingSection />
        </section>
        <section>
            <EventsSection />
        </section>
        <section>
            <ContactSection />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default AboutUs;
