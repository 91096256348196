/** @jsxImportSource @emotion/react */
import React from 'react';
import { SEO, Menu, ContactSection, EventsSection, Footer, TitleSection } from '../../components/common';
import { BenefitsSection, FeaturesSection, IntroductionSection } from './components';

const ForParents = () => {
    return (
        <>
        <SEO 
            title="RUKH KID'Sの特徴" 
            description="RUKH KID'Sの特徴をダンスのメリットとRUKHのメリットの二つに分けて説明するページです。" 
            keywords="RUKH KID'S, ダンス教室, キッズダンス, 子供の習い事, 幼稚園, 保育園, 心身の成長, コミュニケーション力, ダンスのメリット, 自信を育てる"
        />
        <header>
            <Menu />
        </header>
        <main>
            <section>
                    <TitleSection englishTitle="Features of RUKH KID`S" japaneseTitle="RUKH KID`Sの特徴" />
            </section>
            <section>
                <IntroductionSection />
            </section>
            <section>
                <BenefitsSection />
            </section>
            <section>
                <FeaturesSection />
            </section>
            <section>
                <EventsSection />
            </section>
            <section>
                <ContactSection />
            </section>
        </main>
        <footer>
            <Footer />
        </footer>
        </>
    );
};

export default ForParents;
