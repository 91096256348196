/** @jsxImportSource @emotion/react */
import React from 'react';
import { MessageSection } from '../../../../components/common';

const MissionSection = () => {
    return (
        <MessageSection
            title="使命" 
            message="私たちは、ダンスを通じてすべての世代の心と体の健康をサポートします。また、子供たちが楽しみながら成長し、その経験が将来に役立つ教育を提供します。" 
            id="mission"
        />
    );
};

export default MissionSection;
