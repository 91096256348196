import urls from './urls';

const contents = [
    {
        title: "私たちの思い",
        message: "私たちの活動の根底には、ダンスと運動を通じて心と体の健康を育む強い想いがあります。地域社会に根ざし、すべての世代の人々が安心して楽しめる環境作りを目指しています。子供から大人まで、誰もが笑顔で成長できる場を提供しています。",
        reverse: true,
        url: urls.aboutUs,
        imagePath: '/images/100TopPage/104Contents1.png',
    },
    {
        title: "具体的な活動内容",
        message: "・幼稚園や保育園、その他施設への訪問指導<br/>・フィットネスや陸上教室での指導<br/>・高齢者向けの健康運動指導",
        reverse: false,
        url: urls.business,
        imagePath: '/images/100TopPage/104Contents2.png',
    },
    {
        title: "RUKH KID`Sの特徴",
        message: "お子様の健やかな成長を支えるRUKH KID`S<br/>楽しみながらリズム感や協調性を育みます。",
        reverse: true,
        url: urls.forParents,
        imagePath: '/images/100TopPage/104Contents3.png',
    },
];

export default contents;
