import { css } from '@emotion/react';

const SolutionSectionStyles = (theme) => ({
    container: css({
        marginTop: '20px',
        width: '100%',
        padding: '0 40px',
    }),
    wrapper: css({
        width: '100%',
        maxWidth: theme.width.max,
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    title: css({
        marginBottom: '40px',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    }),
    content: css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '20px',
        borderRadius: '50px',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        marginBottom: '60px',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse',
            textAlign: 'center',
        },
    }),
    solutionImageContainer: css({
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '20px',
        [theme.breakpoints.down('md')]: {
            marginRight: '0',
            marginBottom: '20px',
        },
    }),
    logo: css({
        margin: '30px auto',
        height: '200px',
        width: '200px',
    }),
    solutionImage: css({
        width: '100%',
        maxWidth: '300px',
        borderRadius: '10px',
        objectFit: 'contain',
    }),
    textContainer: css({
        flex: '2',
        textAlign: 'left',
        paddingRight: '50px',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
            paddingRight: '0',
        },
    }),
    subTitle: css({
        color: theme.palette.primary.main,
        marginBottom: '20px',
        fontWeight: 'bold',
    }),
    description: css({
        marginBottom: '10px',
    }),
});

export default SolutionSectionStyles;
